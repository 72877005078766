<template>
  <div>
    <b-card>
      <b-card-body class="mt-2">
        <validation-observer ref="approveUserValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Registration ID"
                    rules="required|integer|positive"
                  >
                    <label>Registration ID</label>
                    <b-form-input
                      id="reg_id"
                      v-model="registration_id"
                      :state="errors.length > 0 ? false : null"
                      placeholder="12345"
                      class="text-black"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12" class="mb-1" style="margin: auto">
                <b-form-group>
                  <b-button
                    type="submit"
                    variant="success"
                    pill
                    class="mr-1 text-left"
                    @click="validationForm"
                  >
                    Approve
                  </b-button>

                  <b-button
                    type="submit"
                    variant="warning"
                    pill
                    class="mr-1"
                    @click="validationFormPending"
                  >
                    Pending
                  </b-button>

                  <b-button
                    type="submit"
                    variant="danger"
                    pill
                    class="mr-1"
                    @click="validationFormReject"
                  >
                    Reject
                  </b-button>

                  <b-button
                    type="submit"
                    variant="info"
                    pill
                    class="mr-1"
                    @click="validationUserEmailForm"
                  >
                    Email
                  </b-button>

                  <b-button
                    type="submit"
                    variant="info"
                    pill
                    class="mr-1"
                    @click="validationFormSMS"
                  >
                    Send SMS to User
                  </b-button>

                  <b-button
                    type="submit"
                    variant="secondary"
                    pill
                    v-if="hasPermission('un_approve_user_show')"
                    class="mr-1"
                    @click="validationUserUnApproveForm"
                  >
                    Unapprove
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <RejectReasonModal
      :user="registration_id"
      @modalClosed="onModalClosed"
      :key="`reject-${rejectReasonModalCount}`"
    />
    <pending-reason-modal
      :user="registration_id"
      @modalClosed="onModalClosedPending"
      :key="`pending-${pendingReasonModalCount}`"
    />
    <UserDataEmailModal
      :user="registration_id"
      @modalClosed="onModalClosedEmail"
      :key="`email-${userDataEmailModalCount}`"
    />
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import RejectReasonModal from "@/components/admin/RejectReasonModal.vue";
import PendingReasonModal from "../../components/admin/PendingReasonModal.vue";
import UserDataEmailModal from "@/components/admin/UserDataEmailModal.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    RejectReasonModal,
    UserDataEmailModal,
    PendingReasonModal,
  },
  data() {
    return {
      show: false,
      registration_id: null,
      approve: false,
      rejectReasonModalCount: 0,
      pendingReasonModalCount: 0,
      userDataEmailModalCount: 0,
      user_id: null,
    };
  },
  mixins: [util],
  methods: {
    ...mapActions({
      approveUser: "appData/approveUser",
      rejectUser: "appData/rejectUser",
      updateUser: "appData/updateUser",
      messageUser:"appData/messageUser"
    }),

    async validationForm() {
      const success = await this.$refs.approveUserValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async validationFormSMS() {
      const success = await this.$refs.approveUserValidation.validate();
      if (success) {
        await this.sendSMS();
      }
    },
    async validationUserUnApproveForm() {
      const success = await this.$refs.approveUserValidation.validate();
      if (success) {
        await this.unapproveUser();
      }
    },
    async validationFormPending() {
      const success = await this.$refs.approveUserValidation.validate();
      if (success) {
        await this.pendingReason();
      }
    },
    async validationFormReject() {
      const success = await this.$refs.approveUserValidation.validate();
      if (success) {
        await this.rejectReason();
      }
    },
    async validationUserEmailForm() {
      const success = await this.$refs.approveUserValidation.validate();
      if (success) {
        await this.userEmail();
      }
    },
    async unapproveUser() {
      this.show = true;
      try {
        await this.$swal({
          title: "Are you sure?",
          text: "You cannot revert your action after this !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Unapprove User!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            const res = await this.updateUser({
              payload: {
                is_approved: 3,
              },
              pk: this.registration_id,
            });
            this.show = false;
            if (res.status === 200) {
              this.$swal({
                title: "User unapproved successfully!",
                icon: "success",
                timer: 1500,
              });

              this.registration_id = null;
            }
          }
        });
        this.show = false;
      } catch (e) {
        this.show = false;
        this.displayError(e);
      }
    },
    // async submit() {
    //     this.show = true;
    //     try {
    //         await this.$swal({
    //             title: "Are you sure?",
    //             text: "You cannot revert your action after this !",
    //             icon: "warning",
    //             showCancelButton: true,
    //             confirmButtonText: "Yes, Approve User!",
    //             customClass: {
    //                 confirmButton: "btn btn-primary",
    //                 cancelButton: "btn btn-outline-primary ml-1",
    //             },
    //             buttonsStyling: false,
    //         }).then(async (result) => {
    //             if (result.value) {
    //                 const res = await this.approveUser({
    //                     userId: this.registration_id,
    //                 });
    //                 this.show = false;
    //                 if (res.status === 200) {
    //                     this.$swal({
    //                         title: res.data.msg,
    //                         icon: "success",
    //                         timer: 1500,
    //                     });

    //                     this.registration_id = null;
    //                 }
    //             }
    //         });
    //         this.show = false;
    //     } catch (e) {
    //         this.show = false;
    //         this.displayError(e);
    //     }
    // },
    async submit() {
      this.show = true;
      try {
        const action = await this.$swal({
          title: "Register Uer",
          text: "Select user type to register",
          icon: "warning",
          input: "radio",
          inputOptions: {
            3: "Family of Shaheed",
            2: "Retired",
            1: "Serving Person",
          },
          inputValidator: (value) => {
            if (!value) {
              return "You need to choose an option!";
            }
          },
          confirmButtonText: "Approve User",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          customClass: {
            popup: "custom-swal-popup",
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        if (action.isConfirmed && action.value) {
          const category = action.value;
          console.log(category);
          const res = await this.approveUser({
            userId: this.registration_id,
            user_type: category,
          });
          if (res.status === 200) {
            this.$swal({
              title: "Success",
              text: `User approved`,
              icon: "success",
              timer: 1500,
            });
            this.registration_id = null;
          } else {
            this.displayError(new Error("Operation failed"));
          }
        }
        this.show = false;
      } catch (e) {
        this.show = false;
        this.displayError(e);
      }
    },
    async sendSMS() {
      this.show = true;
      try {
        const res = await this.messageUser({
          userId: this.registration_id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Success",
            text: `SMS Sent`,
            icon: "success",
            timer: 1500,
          });
          this.registration_id = null;
        } else {
          this.displayError(new Error("SMS Failed to send"));
        }
        this.show = false;
      } catch (e) {
        this.show = false;
        this.displayError(e);
      }
    },

    rejectReason() {
      this.rejectReasonModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("reject-reason-modal");
      });
    },
    pendingReason() {
      this.pendingReasonModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("pending-reason-modal");
      });
    },
    userEmail() {
      this.userDataEmailModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("user-email-modal");
      });
    },
    async onModalClosed() {
      this.$bvModal.hide("reject-reason-modal");
    },
    async onModalClosedPending() {
      this.$bvModal.hide("pending-reason-modal");
    },
    async onModalClosedEmail() {
      this.$bvModal.hide("user-email-modal");
    },
  },
  computed: {
    ...mapGetters({
      getUser: "appData/getUser",
      hasPermission: "appData/hasPermission",
    }),
  },
};
</script>

<style>
label {
  font-size: 110%;
  font-weight: 750;
}
.custom-swal-popup {
  width: 90%; /* default width */
  max-width: 600px; /* maximum width */
}

@media (max-width: 600px) {
  .custom-swal-popup {
    width: 85%; /* smaller width for smaller screens */
  }
}
</style>
