<template>
    <b-modal id="pending-reason-modal"
             centered
             size="sm"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             dialog-class="my-dialog-class">
        <template #modal-title>
            <h2 class="m-0">Pending User</h2>
        </template>
        <validation-observer ref="pendingUserFormValidation">
            <b-form @submit.prevent>
                <b-form-row>
                    <b-col md="12">
                        <validation-provider #default="{ errors }"
                                             name="Reason for pending"
                                             rules="required">
                            <b-form-group label="Reason"
                                          label-for="Reason"
                                          :state="errors.length > 0 ? false : null">
                                <v-select id="Reason"
                                          v-model="selectetdReason"
                                          :options="reasons"
                                          placeholder="Select reason for pending of the user"
                                          label="text"
                                          required />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                </b-form-row>
            </b-form>
        </validation-observer>
        <template #modal-footer>
            <b-form-group class="text-right">
                <b-button type="submit" variant="warning" pill @click="validationForm">
                    Pending
                </b-button>
            </b-form-group>
        </template>
        <b-overlay :show="show" spinner-variant="primary" no-wrap />
    </b-modal>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
    import { required } from "@validations";
    import util from "@/util.js";

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            VueSelectPaginated,
        },
        props: {
            user: String,
        },
        mixins: [util],
        data() {
            return {
                show: false,
                required,
                selectetdReason: null,
                reasons: [
                    {
                        text: "Number busy",
                        value: "Number busy",
                    },
                    {
                        text: "Not Attending",
                        value: "Not Attending",
                    },
                    {
                        text: "Number Not Responding",
                        value: "Number Not Responding",
                    },
                    {
                        text: "Phone Powered Off",
                        value: "Phone Powered Off",
                    },
                    {
                        text: "Not Interested",
                        value: "Not Interested",
                    },
                    {
                        text: "Provided wrong number during registering",
                        value: "Provided wrong number during registering",
                    },
                    {
                        text: "Data does not exist in DCR",
                        value: "Data does not exist in DCR",
                    },
                    {
                        text: "Date of birth missing in DCR",
                        value: "Date of birth missing in DCR",
                    },
                    //{
                    //  text: "Blood group does not match",
                    //  value: "Blood group does not match",
                    //},
                    //{
                    //  text: "Arms and services does not match",
                    //  value: "Arms and services does not match",
                    //},
                    {
                        text: "Rank missing in DCR",
                        value: "Rank missing in DCR",
                    },
                    {
                        text: "Service number does not match",
                        value: "Service number does not match",
                    },
                    {
                        text: "DOR missing in DCR",
                        value: "DOR missing in DCR",
                    },
                    {
                        text: "Course missing in DCR",
                        value: "Course missing in DCR",
                    },
                    {
                        text: "Parent unit missing in DCR",
                        value: "Parent unit missing in DCR",
                    },
                    {
                        text: "Last unit missing in DCR",
                        value: "Last unit missing in DCR",
                    },
                    {
                        text: "Shaheed/Deceased name does not match",
                        value: "Shaheed/Deceased name does not match",
                    },
                ],
                registration_id: null,
            };
        },
        async mounted() {
            if (this.user) {
                this.registration_id = this.user;
            }
        },
        methods: {
            ...mapActions({
                pendingUser: "appData/pendingUser",
            }),
            async validationForm() {
                try {
                    const success = await this.$refs.pendingUserFormValidation.validate();
                    if (success) {
                        await this.pending();
                    }
                } catch (error) {
                    this.displayError(error);
                }
            },

            async pending() {
                try {
                    this.show = true;
                    const res = await this.pendingUser({ userId: this.registration_id, reason: this.selectetdReason.value });
                    this.show = false;
                    if (res.status === 200) {
                        this.$swal({
                            title: res.data.msg,
                            icon: "success",
                            timer: 1500,
                        });
                        this.registration_id = null;
                    }
                    this.show = false;
                    await this.$emit('modalClosed')
                } catch (e) {
                    this.show = false;
                    await this.$emit('modalClosed')
                    this.displayError(e);
                }
            },
        },
        computed: {
            ...mapGetters({
                hasRole: "appData/hasRole",
            }),
        },
    };
</script>

<style></style>
