<template>
  <b-modal
    id="reject-reason-modal"
    centered
    size="sm"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    dialog-class="my-dialog-class"
  >
    <template #modal-title> <h2 class="m-0">Reject User</h2> </template>
    <validation-observer ref="rejectUserFormValidation">
      <b-form @submit.prevent>
        <b-form-row>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Reason for rejection"
              rules="required"
            >
              <b-form-group
                label="Reason"
                label-for="Reason"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="Reason"
                  v-model="selectetdReason"
                  :options="reasons"
                  placeholder="Select reason for rejection of the user"
                  label="text"
                  required
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-form-group class="text-right">
        <b-button type="submit" variant="danger" pill @click="validationForm">
          Reject
        </b-button>
      </b-form-group>
    </template>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  props: {
    user: String,
  },
  mixins: [util],
  data() {
    return {
      show: false,
      required,
      selectetdReason: null,
      reasons: [
        {
          text: "Civil Person",
          value: "Civil Person",
        },
        {
          text: "User doesn't want to share information",
          value: "User doesn't want to share information",
        },
      ],
      registration_id:null,
    };
  },
  async mounted() {
    if (this.user) {
      this.registration_id=this.user;
    }
  },
  methods: {
    ...mapActions({
      rejectUser: "appData/rejectUser",
    }),
    async validationForm() {
      try {
        const success = await this.$refs.rejectUserFormValidation.validate();
        if (success) {
          await this.reject();
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    async reject() {
      try {
        this.show = true;
        await this.$swal({
          title: "Are you sure?",
          text: "You cannot revert your action after this !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Reject User!",
          customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            const res = await this.rejectUser({ userId: this.registration_id, reason:this.selectetdReason.value });
            this.show = false;
            if (res.status === 200) {
              this.$swal({
                title: res.data.msg,
                icon: "success",
                timer: 1500,
              });
              this.registration_id = null;
            }
          }
        });
        this.show = false;
        await this.$emit('modalClosed')
      } catch (e) {
        this.show = false;
        await this.$emit('modalClosed')
        this.displayError(e);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasRole: "appData/hasRole",
    }),
  },
};
</script>

<style></style>
