<template>
  <b-modal
    id="user-email-modal"
    centered
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    dialog-class="my-dialog-class"
  >
    <template #modal-title>
      <h2 class="m-0">User Information</h2>
    </template>
    <div id="app" class="container-fluid">
      <form @submit.prevent="submitForm" class="d-flex justify-content-around">
        <div class="col-md-6">
           <div>
            <label>
              <input
                type="checkbox"
                v-model="verifyUser"
                @change="dostnotExistFalse"
                class="custom-checkbox"
              />
              Verify User
            </label>
          </div>

          <div>
            <label>
              <input
                type="checkbox"
                v-model="parent_unit"
                @change="dostnotExistFalse"
                class="custom-checkbox"
              />
              Parent Unit
            </label>
          </div>

          <div>
            <label>
              <input
                type="checkbox"
                v-model="svcNoPrefix"
                @change="dostnotExistFalse"
                class="custom-checkbox"
              />
              SVC No/Prefix
            </label>
          </div>

          <div>
            <label>
              <input
                type="checkbox"
                v-model="course"
                @change="dostnotExistFalse"
                class="custom-checkbox"
              />
              Course
            </label>
          </div>

          <div>
            <label>
              <input
                type="checkbox"
                v-model="dob"
                @change="dostnotExistFalse"
                class="custom-checkbox"
              />
              DOB
            </label>
          </div>
        </div>

        <div class="col-md-6">
          <div>
            <label>
              <input
                type="checkbox"
                v-model="dor"
                @change="dostnotExistFalse"
                class="custom-checkbox"
              />
              DOR
            </label>
          </div>

          <!-- <div>
            <label>
              <input
                type="checkbox"
                v-model="currentAddress"
                @change="dostnotExistFalse"
                class="custom-checkbox"
              />
              Current Address
            </label>
          </div> -->

          <div>
            <label>
              <input
                type="checkbox"
                v-model="lastUnit"
                @change="dostnotExistFalse"
                class="custom-checkbox"
              />
              Last Unit
            </label>
          </div>

          <div>
            <label>
              <input
                type="checkbox"
                v-model="shaheedDeceasedName"
                @change="dostnotExistFalse"
                class="custom-checkbox"
              />
              Shaheed/Deceased Name
            </label>
          </div>

          <div>
            <label>
              <input
                type="checkbox"
                v-model="shaheedDeceasedRank"
                @change="dostnotExistFalse"
                class="custom-checkbox"
              />
              Rank
            </label>
          </div>
        </div>
      </form>

      <div class="col-12 mt-3">
        <h2>Data to be sent via email:</h2>
        <ul>
          <li v-if="parent_unit">Parent Unit</li>
          <li v-if="svcNoPrefix">SVC No/Prefix</li>
          <li v-if="course">Course</li>
          <li v-if="dob">DOB</li>
          <li v-if="verifyUser">Verify User</li>
          <li v-if="dor">DOR</li>
          <!-- <li v-if="currentAddress">Current Address</li> -->
          <li v-if="lastUnit">Last Unit</li>
          <li v-if="shaheedDeceasedName">Shaheed/Deceased Name</li>
          <li v-if="shaheedDeceasedRank">Rank</li>
        </ul>
      </div>
    </div>
    <template #modal-footer>
      <b-form-group class="text-right">
        <b-button
          type="submit"
          variant="info"
          pill
          @click="submitForm"
          :disabled="isSendButtonDisabled"
        >
          {{ isSendButtonDisabled ? "Sending..." : "Send" }}
        </b-button>
      </b-form-group>
    </template>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  props: {
    user: String,
  },
  mixins: [util],
  data() {
    return {
      show: false,
      required,
      // firstName: "",
      // lastName: "",
      name: "",
      cnic: "",
      mobile: "",
      // email: "",
      role: null,
      dataNotExist: false,
      parent_unit: false,
      svcNoPrefix: false,
      course: false,
      dob: false,
      verifyUser: false,
      dor: false,
    //   currentAddress: false,
      lastUnit: false,
      shaheedDeceasedName: false,
      shaheedDeceasedRank: false,
      formSubmitted: false,
      registration_id: null,
      selectedFieldsToSend: {},
      isSendButtonDisabled: false,
    };
  },
  async mounted() {
    if (this.user) {
      this.registration_id = this.user;
    }
  },
  methods: {
    ...mapActions({
      updateUser: "appData/updateUser",
      getRoles: "appData/getPetitionRoles",
      sendEmail: "appData/sendEmail",
    }),
    async submitForm() {
      this.formSubmitted = true;

      // Check if any checkbox is selected
      if (
        !this.parent_unit &&
        !this.svcNoPrefix &&
        !this.course &&
        !this.dob &&
        !this.verifyUser &&
        !this.dor &&
        // !this.currentAddress &&
        !this.lastUnit &&
        !this.shaheedDeceasedName &&
        !this.shaheedDeceasedRank &&
        !this.dataNotExist
      ) {
        // Display error message and return if checkbox not selected
        this.$swal({
          title: "Error submitting form",
          text: "Please select at least one field",
          icon: "error",
        });
        return;
      }

      // Extract selected fields based on checkboxes
      if (this.user) {

        const selectedFields = {
          parent_unit: this.parent_unit,
          svc_or_Prefix_no: this.svcNoPrefix,
          course: this.course,
          dob: this.dob,
          verify_user: this.verifyUser,
          dor: this.dor,
        //   current_address: this.currentAddress,
          last_unit: this.lastUnit,
          shaheed_deceased_name: this.shaheedDeceasedName,
          rank: this.shaheedDeceasedRank,
          userID: this.user,
        };

        this.isSendButtonDisabled = true;

        // Remove fields with falsy values (unchecked checkboxes)
        this.selectedFieldsToSend = Object.fromEntries(
          Object.entries(selectedFields).filter(([_, value]) => value)
        );
      }

      this.show = true;

      // Make an HTTP request to Django backend
      try {
        const response = await this.sendEmail(this.selectedFieldsToSend);

        if (response.data.status === "success") {
          this.$swal({
            title: "Email Send successfully",
            icon: "success",
          });
          this.show = false;
          this.$nextTick(() => {
            this.$bvModal.hide("user-email-modal");
          });
          this.$emit("modalClosed");
          if (response.status === 404) {
            this.$swal({
              title: "User Not Found",
              text: response.data.error_message || "Unknown error",
              icon: "error",
            });
          }
        } else {
          this.$swal({
            title: "Error submitting form",
            text: response.data.error_message || "Unknown error",
            icon: "error",
          });
        }
      } catch (e) {
        this.show = false;
        this.displayError(e);
      } finally {
        // Set loading state to false, whether the request is successful or not
        this.formSubmitted = false;
        this.isSendButtonDisabled = false;
        this.show = false;
      }
    },
    toggleOtherFields() {
      // Enable other fields if "Data does not exist" is checked

      if (this.dataNotExist) {
        this.parent_unit = true;
        this.svcNoPrefix = true;
        this.course = true;
        this.dob = true;
        this.verifyUser = true;
        this.dor = true;
        // this.currentAddress = true;
        this.lastUnit = true;
        this.shaheedDeceasedName = true;
        this.shaheedDeceasedRank = true;
      }
    },
    dostnotExistFalse() {
      // Disable "Data does not exist" if other fields is checked
      if (
        this.parent_unit ||
        this.svcNoPrefix ||
        this.course ||
        this.dob ||
        this.verifyUser ||
        this.dor ||
        // this.currentAddress ||
        this.lastUnit ||
        this.shaheedDeceasedName ||
        this.shaheedDeceasedRank
      ) {
        this.dataNotExist = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      hasRole: "appData/hasRole",
    }),
  },
};
</script>
  
  <style scoped>
.custom-checkbox {
  /* Add your custom styles here */
  /* appearance: none; */
  border: 2px solid #3498db;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: #3498db;
  border: 2px solid #3498db;
  color: #fff;
}
</style>
  